import React from "react";
import "./WebTitle.css";

function WebTitle() {

  return (
    <div className="web_title_container">
      <h1>Brand Home Health Care, Inc.</h1>
    </div>
  );
}

export default WebTitle;
